import { Button } from '@material-ui/core';
import { Auth } from 'aws-amplify';

const LoginPage = ({ federatedIdName, logoVals }) => {
  const REACT_APP_STAGE = process.env.REACT_APP_STAGE;

  return (
    <>
      <div
        style={{ textAlign: 'right', fontWeight: 'bold' }}
      >{`(${REACT_APP_STAGE})`}</div>
      <div
        style={{
          height: '200px',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <div style={{ margin: '2rem', textAlign: 'center' }}>
            <img
              alt="apix"
              className="logo_name"
              src={logoVals.logoBlackUrl}
              height={logoVals.logoBlackHeight}
            />
          </div>

          <h3>Welcome, please sign in</h3>
          <div style={{ textAlign: 'center' }}>
            <Button
              size="large"
              color="primary"
              onClick={() =>
                Auth.federatedSignIn({
                   provider: federatedIdName,
                })
              }
            >
              Log In
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;

import toast from '../components/Toast'
const pako = require('pako')

const api = process.env.REACT_APP_API
const api_x_key = process.env.REACT_APP_API_X_KEY

export default async function lambdaFetch (
  path,
  method,
  webToken,
  body,
  successMessage,
  creds,
  dontShowTimeoutError,
  customErrorMessage
) {
  try {
    const res = await fetch(`${api}/${path}`, {
      timeout: 6000,
      method: method,
      headers: {
        'X-Api-Key': api_x_key,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${webToken}`
      },
      body: body
    })

    // Handle other response errors
    if (!res.ok) {
      if (res.status === 401) {
        toast.persist(
          'Your access token has expired. Please log in again.',
          'error'
        )
        creds.login()  // Trigger login flow
        return null
      }
      if (res.status === 504) {
        throw new Error('Timeout error')
      }
      throw new Error(`Error with status: ${res.status}`)
    }

    const jsonResponse = await res.json()

    if (jsonResponse.isPacko) {
      const unpackedData = JSON.parse(pako.inflate(jsonResponse.data, { to: 'string' }))
      jsonResponse.data = unpackedData
    }

    console.log(jsonResponse)
    // Additional error handling for response data
    if (
      jsonResponse.message ===
      'User is not authorized to access this resource with an explicit deny'
    ) {
      if (path === 'login') {
        return null
      }
      toast.persist(
        'Your access token may be expired. Please try logging back in.',
        'error'
      )
      creds.login()
      return null
    }

    if (jsonResponse.message === 'Endpoint request timed out' && !dontShowTimeoutError) {
      toast.persist('Timeout error', 'error')
      return false
    }
    if (!jsonResponse.success && jsonResponse.error) {
      toast.persist(jsonResponse.error, 'error')
      return false
    } else if (jsonResponse.success && jsonResponse.data) {
      if (jsonResponse.data.maxRows) {
        toast.persist(jsonResponse.data.maxRows, 'warning')
      }
      return jsonResponse
    } else {
      successMessage && toast.success(successMessage)
      return jsonResponse
    }
  } catch (e) {
    console.log("fetching error: ", e)
    toast.persist(customErrorMessage ? customErrorMessage : e.toString(), 'error')
  }
}

import React from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SliderValueLabel } from '@mui/material';

export const currencyFormat = (value, tableMeta, updateValue) => {
  // if(typeof value !== 'string' || typeof value !== 'number') return value
  return (
    <NumberFormat
      value={parseFloat(value).toFixed(2)}
      thousandSeparator={true}
      prefix={'$'}
      displayType="text"
    />
  );
};

export const currencyTextFormat = (value) => {
  // if(typeof value !== 'string' || typeof value !== 'number') return value
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(value);
};

export const dateTimeFormat = (value, tableMeta, updateValue) => {
  if (!value) return '';
  if (typeof value !== 'string') return value;
  let m = moment(value);
  if (!m.isValid()) return '';
  if (new Date(m).getFullYear() < 1800) return ''
  return <div>{moment.utc(value).format('MM/DD/YYYY hh:mm A')}</div>;
};

export const dateFormat = (value, tableMeta, updateValue) => {
  if (!value) return '';
  if (typeof value !== 'string') return value;
  let m = moment(value);
  if (!m.isValid()) return '';
  if (new Date(m).getFullYear() < 1800) return ''
  return moment.utc(value).format('MM/DD/YYYY');
};

export const dateDayjsFormat = (value, tableMeta, updateValue) => {
  if (!value) return '';
  if (typeof value !== 'string') return value;
  let m = dayjs(value);
  if (!m.isValid()) return '';
  if (new Date(m).getFullYear() < 1800) return ''
  return dayjs.utc(value).format('MM/DD/YYYY');
};

export const dateTimeSecondFormat = (value, tableMeta, updateValue) => {
  if (!value) return '';
  if (typeof value !== 'string') return value;
  let m = moment(value);
  if (!m.isValid()) return '';
  if (new Date(m).getFullYear() < 1800) return ''
  return <div>{moment.utc(value).format('MM/DD/YYYY hh:mm:ss A')}</div>;
};
export const noWrapFormat = (value, tableMeta, updateValue) => {
  return (
    <Typography style={{ fontSize: 'inherit' }} noWrap={true}>
      {value}
    </Typography>
  );
};

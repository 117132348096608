import React from 'react'
import { Grid } from '@material-ui/core'

import NumberFormat from 'react-number-format'
// import { reviewCols, certificatesCols } from '../constants/EmployeeCols'
import { AntTabs, AntTab } from '../components/common/Tabs'
import ReactTable from '../components/ReactTable'
import PersonalActHistory from '../components/PersonalActHisotry'
import {
  useCertificatesCols,
  useReviewCols,
  useDisciplinaryCols
} from '../hooks/EmployeeCols'

export default function PersonalActions (props) {
  const [value, setValue] = React.useState(0)

  const reviewCols = useReviewCols()
  const certificatesCols = useCertificatesCols()
  const disciplinaryCols = useDisciplinaryCols()
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const sub2PagePermission =
    props.fetchInitialData.credentials.user.roleActions.reduce((acc, cur) => {
      if (
        cur.type === 'subpage' &&
        cur.filter1 === 'HR' &&
        cur.filter2 === '-1' &&
        cur.active === 1
      )
        acc['-1'] = true
      if (
        cur.type === 'subpage2' &&
        cur.filter1 === 'HR' &&
        cur.filter2 === 'personnel-action'
      ) {
        if (acc[cur.filter3]) {
          acc[cur.filter3] = acc[cur.filter3] ? true : cur.active
        } else {
          acc[cur.filter3] = cur.active === 1 ? true : false
        }
      }
      return acc
    }, {})

  const constructPersonnelTabs = () => {
    const permissions = {}
    const tabs = []
    if (sub2PagePermission['history'] || sub2PagePermission['-1']) {
      tabs.push({
        display: 'History',
        value: 0
      })
      permissions['main'] = true
    }
    if (sub2PagePermission['reviews'] || sub2PagePermission['-1']) {
      tabs.push({
        display: 'Reviews',
        value: 1
      })
      permissions['reviews'] = true
    }
    if (sub2PagePermission['certifications'] || sub2PagePermission['-1']) {
      tabs.push({
        display: 'Certifications',
        value: 2
      })
      permissions['certifications'] = true
    }
    if (sub2PagePermission['disciplinary'] || sub2PagePermission['-1']) {
      tabs.push({
        display: 'Disciplinary Actions',
        value: 3
      })
      permissions['certifications'] = true
    }
    return { tabs, permissions }
  }
  const { tabs, permissions } = constructPersonnelTabs()

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <AntTabs
              color={props.fetchInitialData.credentials.primaryAppColor}
              value={value}
              onChange={handleChange}
              aria-label='ant example'
            >
              {tabs.map(cur => {
                return (
                  <AntTab
                    label={cur.display}
                    value={cur.value}
                    color={props.fetchInitialData.credentials.primaryAppColor}
                  />
                )
              })}
            </AntTabs>
            {value === 0 && (
              <div>
                <PersonalActHistory
                  data={props.data.actions}
                  empId={props.empId}
                  fileName={`Personnel_Action_History_${props.employee.FIRST_NAME}_${props.employee.LAST_NAME}`}
                  company={props.company}
                  accessToken={
                    props.fetchInitialData.credentials.user.accessToken
                  }
                  fetchInitialData={props.fetchInitialData}
                />
              </div>
            )}
            {value === 1 && (
              <div>
                <ReactTable cols={reviewCols} data={props.data.reviews} />
              </div>
            )}
            {value === 2 && (
              <div>
                <ReactTable
                  cols={certificatesCols}
                  data={props.data.certificates}
                />
              </div>
            )}
            {value === 3 && (
              <div>
                <ReactTable
                  cols={disciplinaryCols}
                  data={props.data.disciplinaryActions}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

import React, { useState } from 'react'
import {
  IconButton,
  Slide,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import TableMui from './TableMui'
import getTableOptions from '../constants/TableOptions'
import { poCols, poReactTableCols } from '../constants/VendorCols'
import PoDetail from './PoDetail'
import LambdaFetch from '../functions/FetchFromLambda'
import { makeStyles } from '@material-ui/core/styles'
import { smallScreenWidth } from '../constants/AppConstants'
import PoDetailDialog from './PoDetailDialog'
import { ServerSideTable } from './ServerSideTable'
import ReactTableServerSide from '../components/ReactTableServerSide'
import {usePoReactTableCols} from '../hooks/VendorCols'
import { DashboardAthenaTableServerSide } from '../components/DashboardAthenaTableServerSide'
import fetchFromAthena from '../functions/FetchFromAthena'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: props => props.primaryColor,
    height: '60px'
  }
}))

export function VendorPosServerSide (props) {
  const { credentials } = props.fetchInitialData
  const classes = useStyles({ primaryColor: credentials.primaryAppColor })
  const [headers, setHeaders] = React.useState({
    company: null,
    poNum: null,
    code: null
  })
  // const [state, setstate] = useState({
  //   open: false,
  //   poHeaderData: null,
  //   poDetailData: null,
  //   poReceive: null,
  //   poReleases: null,
  //   fetched: false
  // })
  // const updateRelease = release => {
  //   getPo(headers.poNum, headers.company, headers.code, release)
  // }
  // const getPo = async (id, comp, code) => {
  //   setHeaders({company: comp, poNum: id, code: code})
  //   try {
  //     if (!id || !comp) return null
  //     setstate({ ...state, open: true })

  //     const resp = await LambdaFetch(
  //       'po',
  //       'post',
  //       credentials.user.accessToken,
  //       JSON.stringify({
  //         action: 'get-po',
  //         company: comp,
  //         po: id,
  //         code: code
  //       }),
  //       '',
  //       credentials
  //     )
  //     setstate({
  //       ...state,
  //       poHeaderData: resp.data.poHeader[0],
  //       poDetailData: resp.data.poDetail,
  //       poReceive: resp.data.poReceive,
  //       poReleases: resp.data.poReleases,
  //       open: true
  //     })
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  const tableDataTransformation = rows => {
    return rows.map(row => {
      return {
        ...row,
        PO_NUMBER:
        row.PO_NUMBER && row.COMPANY ? (
          <div
            className='editLink'
            onClick={() => {
              const win = window.open(
                `/po?company=${row.COMPANY}&po=${row.PO_NUMBER}&pocode=${row.PO_CODE}`,
                '_blank'
              )
              win.focus()
            }}
          >
            {row.PO_NUMBER}
          </div>
        ) : (
          ''
        )
      }
    })
  }

  // const { poHeaderData, poDetailData, open,poReceive ,poReleases} = state
  const poServerSideCols = usePoReactTableCols();

  //   const pos = props.data.map(p => {
  //     return {
  //       ...p,
  //       view: (
  //         <div
  //           className='editLink'
  //           onClick={() => getPo(p.PO_NUMBER, p.COMPANY, p.PO_CODE)}
  //         >
  //           view
  //         </div>
  //       )
  //     }
  //   })

  return (
    <>
      {/* <PoDetailDialog
        {...props}
        open={open}
        poHeaderData={poHeaderData}
        poDetailData={poDetailData}
        poReceive={poReceive}
        poReleases={poReleases}
        updateRelease={updateRelease}
        close={() =>
          setstate({
            ...state,
            poHeaderData: null,
            poDetailData: null,
            poReleases: null,
            updateRelease: null,
            open: false
          })
        }
      /> */}

      <div style={{ marginTop: '1rem ' }}>

      <DashboardAthenaTableServerSide
          {...props}
          params={{ vendor: props.vendor, vendorGroup: props.selectedGroup }}
          tableColumns={poServerSideCols}
          reportName='po_by_vendor_read_serverside'
          tableTitle={''}
          callback={()=>{}}
          transform={tableDataTransformation}
          separatedDateFilters={{
            PO_DATE: ['PO_DATE_FROM', 'PO_DATE_TO'],
          }}
        />

{/* 
        <ReactTableServerSide
          {...props}
          params={{ vendor: props.vendor, vendorGroup: props.selectedGroup }}
          action='vendor-po-serverside'
          apiResource={'vendors'}
          transform={tableDataTransformation}
          tableTitle={''}
          tableColumns={poServerSideCols}
          noPagination
          noSort
          downloadAction={'vendor-po-serverside-download'}
          separatedDateFilters={{
            PO_DATE: ['PO_DATE_FROM', 'PO_DATE_TO'],
          }}
        /> */}

      </div>

      {/* <TableMui cols={props.showVendor ? poWithVendorCols : poCols} data={pos} options={options} title='' /> */}
    </>
  )
}

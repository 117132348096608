import React, { useEffect, useState, Fragment } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import Routes from './Routes';
import LambdaFetch from './functions/FetchFromLambda';
import AppBar from './components/AppBar';
import { Button } from '@material-ui/core';

import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import './App.css';
import LoginPage from './LoginPage';

function AppCognito() {
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    isAuth: false,
    doneTokenCheck: false,
    isGettingProfile: true,
    isInvalidUser: false,
    user: {},
  });
  const [federatedIdName, setFederatedIdName] = useState('Google');
  const [token, setToken] = useState(null);
  const [appVals, setAppVals] = useState({
    appWidth: 1000,
    primaryAppColor: '#1482C5',
    trinaryAppColor: '#0d6eaa',
  });
  const [logoVals, setLogoVals] = useState({
    logoUrl: '12',
    logoHeight: '40px',
    logoHeightSmall: '30px',
    logoBlackUrl: null
  });
  useEffect(() => {
    const fetchData = async () => {
      window.addEventListener('resize', () => calcSize());

      Hub.listen('auth', ({ payload: { event, data } }) => {
        switch (event) {
          case 'signIn':
            auditUser(
              data.signInUserSession.idToken.payload.email,
              data.signInUserSession.idToken.jwtToken
            );
            break;
          case 'cognitoHostedUI':
            setToken('grating...');
            getToken().then((userToken) =>
              setToken(userToken.idToken.jwtToken)
            );
            break;
          case 'signOut':
            setToken(null);
            break;
          case 'signIn_failure':
          case 'cognitoHostedUI_failure':
            console.log('Sign in failure', data);
            break;
          default:
            break;
        }
      });
      await clientInfo();
      authUser();
    };
    fetchData();
  }, []);

  const calcSize = () => {
    setAppVals({ ...appVals, appWidth: window.innerWidth });
  };

  async function auditUser(user, token) {
    await LambdaFetch(
      'audit',
      'post',
      token,
      JSON.stringify({
        action: 'write',
        type: 'login',
      }),
      '',
      user
    );
  }
  async function authUser() {
    const ses = await Auth.currentSession()
      .then((session) => session)
      .catch(() => {
        setState({ ...state, doneTokenCheck: true });
        console.log('Not signed in');
      });

    if (!ses) return null;
    if (!ses.idToken) return null;

    const curJwt = ses.idToken.jwtToken;

    if (!curJwt) {
      return null;
    }

    const currentSessionUser = await getUser();
    const { email } = currentSessionUser.signInUserSession.idToken.payload;

    getUserInfo(email, curJwt);
  }

  async function getUserInfo(email, jwt) {
    const userToken = await LambdaFetch(
      'login',
      'post',
      null,
      JSON.stringify({
        action: 'cognito-login',
        accessToken: jwt,
        user: email,
        isLoggingIn: true,
      }),
      '',
      null
    );

    const pagePermission = userToken.data.roleActions.reduce((acc, cur) => {
      if (cur.type === 'page') {
        if (acc[cur.filter1]) {
          acc[cur.filter1] = acc[cur.filter1] ? true : cur.active;
        } else {
          acc[cur.filter1] = cur.active === 1 ? true : false;
        }
      }
      return acc;
    }, {});

    const subPagePermission = userToken.data.roleActions.reduce((acc, cur) => {
      if (cur.type === 'subpage') {
        let prop = `${cur.filter1}/${cur.filter2}`
        if (acc[prop]) {
          acc[prop] = acc[prop] ? true : cur.active;
        } else {
          acc[prop] = cur.active === 1 ? true : false;
        }
      }
      return acc;
    }, {});

    setState({
      ...state,
      doneTokenCheck: true,
      isAuth: true,
      user: {
        ...userToken.data.userInfo,
        accessToken: jwt,
        pagePermission: pagePermission,
        subPagePermission: subPagePermission,
        roleActions: userToken.data.roleActions,
      },
    });
  }

  async function clientInfo() {
    const resp = await LambdaFetch(
      'login',
      'post',
      null,
      JSON.stringify({
        action: 'get-client-values',
      }),
      '',
      null
    );
    const awsRegion = resp.data.clientValues.find(
      (row) => row.R_KEY === 'aws-region'
    ).R_VALUE;
    const cognitoUserPoolId = resp.data.clientValues.find(
      (row) => row.R_KEY === 'cognito-user-pool-id'
    ).R_VALUE;
    const cognitoClientId = resp.data.clientValues.find(
      (row) => row.R_KEY === 'cognito-client-id'
    ).R_VALUE;
    const cognitoDomain = resp.data.clientValues.find(
      (row) => row.R_KEY === 'cognito-domain'
    ).R_VALUE;
    const federatedIdName = resp.data.clientValues.find(
      (row) => row.R_KEY === 'federated-id-name'
    ).R_VALUE;
    const redirectURL = resp.data.clientValues.find(
      (row) => row.R_KEY === 'redirect-url'
    ).R_VALUE;
    const logoUrl = resp.data.clientValues.find(
      (row) => row.R_KEY === 'logo-url'
    ).R_VALUE;
    const logoHeight = resp.data.clientValues.find(
      (row) => row.R_KEY === 'logo-height'
    ).R_VALUE;
    const logoHeightSmall = resp.data.clientValues.find(
      (row) => row.R_KEY === 'logo-height-small'
    ).R_VALUE;
    const logoBlackUrl = resp.data.clientValues.find(
      (row) => row.R_KEY === 'logo-black'
    ).R_VALUE;
    const logoBlackHeight = resp.data.clientValues.find(
      (row) => row.R_KEY === 'logo-height-black'
    )?.R_VALUE;
    
    const colorScheme = resp.data.clientValues.reduce((acc, cur) => {
      if (cur.R_KEY === 'primary_color' && cur.R_ACTIVE === 1) {
        acc['primaryColor'] = cur.R_VALUE;
      }
      if (cur.R_KEY === 'trinary_color' && cur.R_ACTIVE === 1) {
        acc['trinaryColor'] = cur.R_VALUE;
      }
      return acc;
    }, {});

    setAppColor(colorScheme.primaryColor, colorScheme.trinaryColor);

    const url =
      window.location.host === 'localhost:3000'
        ? 'http://localhost:3000'
        : redirectURL;

    Amplify.configure({
      Auth: {
        region: awsRegion,
        userPoolId: cognitoUserPoolId,
        userPoolWebClientId: cognitoClientId,
        oauth: {
          domain: cognitoDomain,
          scope: ['email', 'openid'],
          redirectSignIn: url,
          redirectSignOut: url,
          responseType: 'code',
        },
      },
    });
    setFederatedIdName(federatedIdName);
    setLogoVals({ ...logoVals, logoUrl: logoUrl, logoHeight, logoHeightSmall, logoBlackUrl, logoBlackHeight });
    return null;
  }
  const setAppColor = (primaryColor, trinaryColor) => {
    const root = document.documentElement;
    const primary = primaryColor ? primaryColor : '#1482C5';
    const trinary = trinaryColor ? trinaryColor : '#0d6eaa';
    root.style.setProperty('--primary-color', primary);
    root.style.setProperty('--trinary-color', trinary);
    setState({ ...state, primaryAppColor: primary, trinaryAppColor: trinary });
  };

  function getToken() {
    return Auth.currentSession()
      .then((session) => session)
      .catch((err) => console.log(err));
  }

  async function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((currentUser) => currentUser)
      .catch(() => console.log('Not signed in'));
  }

  async function signOut() {
    try {
      await LambdaFetch(
        'audit',
        'post',
        state.user.accessToken,
        JSON.stringify({
          action: 'write',
          type: 'logout',
        }),
        '',
        state.user.accessToken
      );
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  const login = () => {
    Auth.federatedSignIn({
      provider: federatedIdName,
    });
  };

  const createSnack = (message, type, duration) => {
    enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      variant: type,
      autoHideDuration: duration,
    });
  };

  const getMuiTheme = () =>
    createTheme({
      palette: {
        primary: { main: '#1482C5' },
      },
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            padding: '3px 6px',
          },
        },
        MUIDataTableSelectCell: {
          checked: { color: `${state.primaryAppColor} !important` },
        },
      },
    });

  if (!state.doneTokenCheck) {
    return null;
  }
  return (
    <>
      <div className="App">
        <MuiThemeProvider theme={getMuiTheme()}>
          <div style={{ margin: '0 auto' }}>
            <AppBar
              appState={{ ...state, ...appVals, ...logoVals }}
              logout={signOut}
              login={login}
              user={state.user}
            >
              <div style={{ maxWidth: '1400px', margin: 'auto' }}>
                <div>
                  {state.isAuth ? (
                    <Routes
                      login={login}
                      // userRoles={this.userRoles}
                      createSnack={createSnack}
                      credentials={{
                        ...state,
                        ...appVals,
                        login: login,
                      }}
                    />
                  ) : (
                    <LoginPage
                      federatedIdName={federatedIdName}
                      logoVals={logoVals}
                    />
                  )}
                </div>
              </div>
            </AppBar>
          </div>
        </MuiThemeProvider>
      </div>
    </>
  );
}

export default AppCognito;

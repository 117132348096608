import React, { useState, useEffect } from 'react'
import {
  Typography,
  Grid,
  MenuItem,
  TextField,
  Switch,
  Card,
  CardContent,
  FormControlLabel,
  InputAdornment,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Slide
} from '@material-ui/core'
import LambdaFetch from '../functions/FetchFromLambda'
import CloseIcon from '@material-ui/icons/Close'
import SimpleAutoComplete from '../functions/SimpleAutoComplete'
import LoadingButton from '../components/LoadingButton'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const initialParam = {
  name: '',
  displayName: '',
  fieldType: 'date',
  dataType: 'varchar(45)',
  isRequired: true,
  query: '',
  table: '',
  category: '',
  autoCategory: '',
  dropdown: '',
  dependsOn: '',
  multiSelect: false,
  procedureParams: ''
}

export default function AthenaReportBuilder (props) {
  const [state, setstate] = useState({
    params: [
      {
        name: '',
        displayName: '',
        fieldType: 'date',
        dataType: 'varchar(45)',
        isRequired: true,
        query: '',
        table: '',
        category: '',
        autoCategory: '',
        dropdown:'',
        procedure:'',
        dependsOn: '',
        multiSelect: false,
        procedureParams: ''
      }
    ],
    reportCode: '',
    addNewReport: false,
    addingNewReport: false,
    clientS3BucketName: 'name-of-s3-bucket'
  })
  const [reportState, setReportState] = useState({
    params: [],
    reportOptions: [],
    selectedReport: null,
    report: null
  })

  const { credentials } = props.fetchInitialData

  const fetchReports = async () => {
    try {
      const resp = await LambdaFetch(
        'admin',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-reports-athena'
        }),
        '',
        credentials
      )
      setstate({...state, clientS3BucketName: resp.data.clientS3BucketName})
      setReportState({ ...reportState, reportOptions: resp.data.reports})
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (name, value) => {
    setstate({...state, 
        reportCode: value.sql_text ? value.sql_text : '',
    })
    setReportState({
      ...reportState,
      [name]: value ? value : '',
      params: value ? JSON.parse(value.parameters) : []
    })
  }

  const handleReportValueChange = (name, value) => {
    const updatedSelectedReport = {
      ...reportState.selectedReport,
      [name]: value
    }
    setReportState({
      ...reportState,
      selectedReport: updatedSelectedReport
    })
  }

  const handleSave = async e => {
    e.preventDefault()
    try {
      const {
        report_name,
        category,
        description,
        id,
        query_id,
        delete_flag,
        built_query
      } = reportState.selectedReport
      setstate({ ...state, savingReport: true })

      const { params } = reportState
      const reportData = {
        id: id,
        report_name: report_name,
        category: category,
        query_id: query_id,
        sql_text: state.reportCode,
        description: description,
        parameters: JSON.stringify(params),
        delete_flag: delete_flag,
        built_query: built_query
      }
      
     await LambdaFetch(
        'admin',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'update-athena-report',
          reportData
        }),
        'Successfully updated report',
        credentials
      )

      setstate({ ...state, savingReport: false })
      fetchReports()

    } catch (e) {
      console.log(e)
    }
  }

  const handleNewReport = async e => {
    e.preventDefault()
    setstate({ ...state, addingNewReport: true })
    const reportData = {
      report_name: e.target.report_name_new.value,
      category: e.target.category_new.value
    }

    await LambdaFetch(
      'admin',
      'post',
      credentials.user.accessToken,
      JSON.stringify({
        action: 'add-athena-report',
        reportData
      }),
      'Successfully created report',
      credentials
    )

    setstate({ ...state, addingNewReport: false, addNewReport: false })
    fetchReports()
  }

  const handleParamChange = (name, value, idx) => {
    const updatedParams = reportState.params.map((p, index) => {
      if (idx === index) {
        return { ...p, ...{ [name]: value } }
      } else return p
    })
    setReportState({ ...reportState, params: updatedParams })
  }
  return (
    <div>
      <div style={{ margin: '1rem' }}>
        <div>
          <Button
            className='primaryVLButton'
            variant='contained'
            color='primary'
            style={{ float: 'right' }}
            onClick={() => setstate({ ...state, addNewReport: true })}
          >
            Add New Report
          </Button>

          {state.addNewReport && (
            <Dialog
              open={state.addNewReport ? true : false}
              TransitionComponent={Transition}
              maxWidth='xl'
              scroll='body'
              keepMounted
            >
              <DialogTitle>New Report</DialogTitle>
              <form onSubmit={handleNewReport}>
                <DialogContent>
                  <Grid
                    container
                    direction='row'
                    alignItems='stretch'
                    spacing={2}
                  >
                    <Grid item>
                      <TextField
                        margin='dense'
                        variant='outlined'
                        required
                        id='report_name_new'
                        label='Report Name'
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        margin='dense'
                        variant='outlined'
                        required
                        id='category_new'
                        label='Report Category'
                        autoComplete='off'
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setstate({ ...state, addNewReport: false })}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    label='Submit'
                    isLoading={state.addingNewReport}
                    color='primaryVLButton'
                    buttonType='submit'
                  />
                </DialogActions>
              </form>
            </Dialog>
          )}

          <SimpleAutoComplete
            id='rpt'
            name='rpt'
            width='300px'
            variant='standard'
            label='Report'
            value={reportState.selectedReport}
            onChange={val => {
                handleChange('selectedReport', val)}
            }
            options={reportState.reportOptions}
            getOptionLabel={val => `${val.report_name} (${val.category})`}
            renderOption={option => {
              return (
                <div>
                  <Typography style={{fontSize: '14px'}} variant='body1' color='textPrimary'>
                    {`${option.report_name} (${option.category})`}
                  </Typography>
                  <Typography style={{fontSize: '11px'}} variant='body2' color='textSecondary'>
                    {!option.delete_flag ? 'Active' : 'Inactive'}
                  </Typography>
                </div>
              )
            }}
          />
          {reportState.selectedReport && (
            <>
              <Card style={{ width: '100%', margin: '1rem auto' }}>
                <CardContent>
                  <form onSubmit={handleSave}>
                    <Grid
                      container
                      direction='row'
                      alignItems='stretch'
                      spacing={2}
                    >
                      <Grid item>
                        <TextField
                          required
                          id='report_name'
                          onChange={e =>
                            handleReportValueChange(
                              'report_name',
                              e.target.value
                            )
                          }
                          value={
                            reportState.selectedReport.report_name
                              ? reportState.selectedReport.report_name
                              : ''
                          }
                          label='Report Name'
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          required
                          id='category'
                          onChange={e =>
                            handleReportValueChange('category', e.target.value)
                          }
                          value={
                            reportState.selectedReport.category
                              ? reportState.selectedReport.category
                              : ''
                          }
                          label='Report Category'
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          id='query_id'
                          onChange={e =>
                            handleReportValueChange(
                              'query_id',
                              e.target.value
                            )
                          }
                          value={
                            reportState.selectedReport.query_id
                              ? reportState.selectedReport.query_id
                              : ''
                          }
                          label='Query ID'
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id='description'
                          onChange={e =>
                            handleReportValueChange(
                              'description',
                              e.target.value
                            )
                          }
                          value={
                            reportState.selectedReport.description
                              ? reportState.selectedReport.description
                              : ''
                          }
                          label='Description'
                        />
                      </Grid>
                      <Grid item xs={12}>

                      <TextField
                      style={{ marginTop: '0.5rem' }}
                      id='sqlCodeConvert'
                      label='SQL Code'
                      fullWidth
                      multiline
                      rows={6}
                      value={state.reportCode}
                      onChange={e => {
                        e.preventDefault()
                        setstate({ ...state, reportCode: e.target.value })
                      }}
                      variant='outlined'
                    />
</Grid>

<Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              color='primary'
                              checked={reportState.selectedReport.built_query === 1}
                              onChange={() => {
                                const updatedSelectedReport = {
                                  ...reportState.selectedReport,
                                  built_query: reportState.selectedReport
                                    .built_query
                                    ? 0
                                    : 1
                                }
                                setReportState({
                                  ...reportState,
                                  selectedReport: updatedSelectedReport
                                })
                              }}
                              name='built_query'
                            />
                          }
                          label='Built Query'
                          labelPlacement='start'
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              color='primary'
                              checked={!reportState.selectedReport.delete_flag}
                              onChange={() => {
                                const updatedSelectedReport = {
                                  ...reportState.selectedReport,
                                  delete_flag: reportState.selectedReport
                                    .delete_flag
                                    ? 0
                                    : 1
                                }
                                setReportState({
                                  ...reportState,
                                  selectedReport: updatedSelectedReport
                                })
                              }}
                              name='delete_flag'
                            />
                          }
                          label='Active'
                          labelPlacement='start'
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <LoadingButton
                          label='Save'
                          isLoading={state.savingReport}
                          color='primaryVLButton'
                          buttonType='submit'
                        />
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>

              <Grid container direction='row' alignItems='stretch' spacing={2}>
                {reportState.params.map((p, index) => {
                  return (
                    <Grid item key={index}>
                      <CreateParameter
                        index={index}
                        color={credentials.primaryAppColor}
                        values={p}
                        updateParams={handleParamChange}
                        delete={() =>
                          setReportState({
                            ...reportState,
                            params: reportState.params.filter(
                              (p, idx) => idx !== index
                            )
                          })
                        }
                      />
                    </Grid>
                  )
                })}
              </Grid>
              <Grid item xs={12}>
                <Button
                  className='primaryVLButton'
                  variant='contained'
                  color='primary'
                  style={{ margin: '1rem' }}
                  onClick={() => {
                    setReportState({
                      ...reportState,
                      params: [
                        ...reportState.params,
                        Object.assign({}, initialParam)
                      ]
                    })
                  }}
                >
                  Add Param
                </Button>
              </Grid>
              <div style={{ marginTop: '1rem' }}>
                <Card style={{ width: '100%', margin: '1rem auto' }}>
                  <CardContent>
                    <Typography variant='h6'>Report Converter</Typography>

                    <TextField
                      style={{ marginTop: '0.5rem' }}
                      id='sqlCodeConvert'
                      label='SQL Code'
                      fullWidth
                      multiline
                      rows={12}
                      value={state.sql_text}
                      onChange={e => {
                        e.preventDefault()
                        setstate({ ...state, reportCode: e.target.value })
                      }}
                      variant='outlined'
                    />

                    <div style={{ marginTop: '1rem' }}>


                        {reportState.selectedReport.sql_text}
                      </div>
                  </CardContent>
                </Card>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

function CreateParameter (props) {
  const {
    name,
    displayName,
    fieldType,
    dataType,
    query,
    table,
    category,
    autoCategory,
    dropdown,
    procedure,
    dependsOn,
    procedureParams,
    multiSelect,
    isRequired
  } = props.values
  const textfield = {
    marginTop: '0.5rem'
  }

  const inputProps = {
    startAdornment: (
      <InputAdornment position='start'>
        <div></div>
      </InputAdornment>
    )
  }

  const handleChange = event => {
    event.preventDefault()

    const name = event.target.name
    let value
    if (name === 'isRequired' || name == 'multiSelect') {
      value = event.target.checked
    } else {
      value = event.target.value
    }
    props.updateParams(name, value, props.index)
  }
  return (
    <div>
      <Card style={{ width: '360px' }}>
        <CloseIcon style={{ float: 'right' }} onClick={props.delete} />
        <CardContent>
          <div style={textfield}>
            <TextField
              id='name'
              fullWidth
              value={name}
              name='name'
              label='Name'
              onChange={handleChange}
              autoComplete='off'
              InputProps={inputProps}
            />
          </div>
          <div style={textfield}>
            <TextField
              id='displayName'
              fullWidth
              value={displayName}
              name='displayName'
              label='DisplayName'
              onChange={handleChange}
              autoComplete='off'
              InputProps={inputProps}
            />
          </div>
          <div style={textfield}>
            <TextField
              id='fieldType'
              fullWidth
              select
              label='Field Type'
              name='fieldType'
              value={fieldType}
              onChange={handleChange}
              InputProps={inputProps}
            >
              {['smart-autocomplete', 'dynamicList','autocomplete', 'autocomplete-all','autocomplete-serverside', 'date', 'string', 'int'].map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div style={textfield}>
          {(fieldType === 'autocomplete-all' || fieldType === 'autocomplete-serverside') && (
              <>
              <TextField
                id='autoCategory'
                fullWidth
                value={autoCategory}
                name='autoCategory'
                label='autoCategory'
                onChange={handleChange}
                InputProps={inputProps}
              />
          <TextField
                id='dropdown'
                fullWidth
                value={dropdown}
                name='dropdown'
                label='Dropdown'
                onChange={handleChange}
                InputProps={inputProps}
              />
</>

            )}
            {fieldType === 'smart-autocomplete'&& (
        <>
        <TextField
          id='procedure'
          fullWidth
          value={procedure}
          name='procedure'
          label='Procedure'
          onChange={handleChange}
          InputProps={inputProps}
        />
         <TextField
          id='procedureParams'
          fullWidth
          value={procedureParams}
          name='procedureParams'
          label='procedure Params'
          onChange={handleChange}
          InputProps={inputProps}
        />
        <TextField
          id='dependsOn'
          fullWidth
          value={dependsOn}
          name='dependsOn'
          label='Depends On'
          onChange={handleChange}
          InputProps={inputProps}
        />
</>

            )}
            {fieldType === 'autocomplete'&& (
              <>
              <TextField
                id='table'
                fullWidth
                value={table}
                name='table'
                label='Table'
                onChange={handleChange}
                InputProps={inputProps}
              />
          <TextField
                id='category'
                fullWidth
                value={category}
                name='category'
                label='Category'
                onChange={handleChange}
                InputProps={inputProps}
              />
</>

            )}
            {fieldType === 'dynamicList' && (
              <TextField
                id='query'
                multiline
                fullWidth
                value={query}
                name='query'
                label='Query'
                onChange={handleChange}
                autoComplete='off'
                InputProps={inputProps}
              />
            )}
          </div>
          <div style={textfield}>
            <TextField
              id='dataType'
              fullWidth
              name='dataType'
              value={dataType}
              onChange={handleChange}
              label='Data Type'
              autoComplete='off'
              InputProps={inputProps}
            />
          </div>
          <div style={textfield}>
            <FormControlLabel
              control={
                <Switch
                  color='primary'
                  checked={multiSelect}
                  onChange={handleChange}
                  name='multiSelect'
                />
              }
              label='MultiSelect'
              labelPlacement='start'
            />
          </div>
          <div style={textfield}>
            <FormControlLabel
              control={
                <Switch
                  color='primary'
                  checked={isRequired}
                  onChange={handleChange}
                  name='isRequired'
                />
              }
              label='Required'
              labelPlacement='start'
            />
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  Button,
  Paper,
  InputAdornment,
  TableFooter,
  TableRow,
  TableCell,
  Slide,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import { am70Cols } from '../constants/AssetCols'
import TableMui from '../components/TableMui'
import LoadingCircle from '../components/common/LoadingCircle'
import SimpleAutoComplete from '../functions/SimpleAutoComplete'
import getTableOptions from '../constants/TableOptions'
import { smallScreenWidth } from '../constants/AppConstants'
import clsx from 'clsx'
import { currencyTextFormat } from '../utils/formatter'
import AssetDetail from '../components/AssetDetail'
import ReactTable from '../components/ReactTable'
import { useAm70ReactTableCols } from '../hooks/AssetCols'
import fetchFromAthena from '../functions/FetchFromAthena'
import { DashboardAthenaTableServerSide } from '../components/DashboardAthenaTableServerSide'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  footerCell: {
    backgroundColor: '#F5F5F5',
    borderBottom: 'none',
    zIndex: 999
  },
  stickyFooterCell: {
    position: 'sticky',
    bottom: 0,
    zIndex: 100
  },
  rightTableHead: {
    '& > span': {
      justifyContent: 'flex-end',
      marginRight: '0'
    }
  },
  appBar: {
    position: 'relative',
    backgroundColor: props => props.primaryColor,
    height: '60px'
  }
}))

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const defaultHeaders = {
  company: '',
  leaseCompany: '',
  lease: '',
  location: '',
  division: '',
  assetType: '',
  subType: '',
  assetGroup: ''
}
export default function AssetAnalysisServerSide (props) {
  const [state, setstate] = useState({
    isLoading: false,
    data: null,
    fetched: false,
    tableKey: JSON.stringify(defaultHeaders)
  })
  const [headers, setHeaders] = useState(defaultHeaders)
  const [showTable, setShowTable] = useState(false)

  const [headerOptions, setHeaderOptions] = useState({
    company: [],
    leaseCompany: [],
    lease: [],
    location: [],
    division: [],
    assetType: [],
    subType: [],
    assetGroup: []
  })
  const [clearHeader, setClearHeaders] = React.useState(false)
  const [assetData, setAssetData] = useState({
    open: false,
    assetData: null,
    items: [],
    books: []
  })

  const am70ReactTableCols = useAm70ReactTableCols()

  const { credentials } = props.fetchInitialData
  const classes = useStyles({ primaryColor: credentials.primaryAppColor })

  useEffect(() => {
    async function fetchMyAPI () {
      fetchData()
    }
    fetchMyAPI()
  }, [])

  const fetchData = async () => {
    try {
      const resp = await LambdaFetch(
        'asset-analysis',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-headers'
        }),
        '',
        credentials
      )

      setstate({ ...state, fetched: true })
      setHeaderOptions({ ...headerOptions, ...resp.data })
    } catch (e) {
      console.log(e)
    }
  }
  const handleChange = (name, value) => {
    setHeaders({ ...headers, [name]: !!value ? value.id : null })
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (JSON.stringify(headers) === state.tableKey) return null
    setShowTable(true)
    setstate({ ...state, isLoading: true, tableKey: JSON.stringify(headers) })
  }
  const handleCallback = (values, error) => {
    if (error) {
      setstate({ ...state, isLoading: false })
      setShowTable(false)
      return null
    }
    setstate({ ...state, isLoading: false })
  }

  const tableDataTransformation = rows => {
    return rows.map(row => {
      return {
        ...row,
        ASSET_LINK: (
          <div
            className='editLink'
            onClick={() => getAsset(row.COMPANY, row.ASSET)}
          >
            {row.ASSET}
          </div>
        )
      }
    })
  }
  const getAsset = async (comp, id) => {
    try {
      if (!id || !comp) return null
      setAssetData({ ...assetData, open: true })

      const queries = ['am_asset_read', 'am_item_read', 'am_book_read']

      const data = await Promise.allSettled(
        queries.map(query =>
          fetchFromAthena({
            queryName: query,
            headers: {
              company: comp,
              asset: id
            },
            accessToken: credentials.user.accessToken,
            credentials: credentials
          })
        )
      ).then(results => {
        return results
      })

      const [main, items, books] = [
        data[0].value[0],
        data[1].value,
        data[2].value
      ]

      const assetDesc = main.ASSET_DESC
      setAssetData({
        ...assetData,
        assetData: main,
        open: true,
        items: items.map(row => {
          return {
            ...row,
            COMPANY: comp,
            ASSET: id,
            ASSET_DESC: assetDesc
          }
        }),
        books: books.map(row => {
          return {
            ...row,
            COMPANY: comp,
            ASSET: id,
            ASSET_DESC: assetDesc
          }
        }),
        isLoading: false
      })
    } catch (e) {
      console.log(e)
    }
  }

  if (!state.fetched) {
    return <LoadingCircle />
  }

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <Dialog
        open={assetData.open}
        TransitionComponent={Transition}
        maxWidth='xl'
        fullWidth
        fullScreen={
          props.fetchInitialData.credentials.appWidth < smallScreenWidth
            ? true
            : false
        }
        scroll='body'
        keepMounted
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() =>
                setAssetData({
                  ...assetData,
                  assetData: null,
                  items: [],
                  books: [],
                  open: false
                })
              }
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {assetData.open && (
          <DialogContent style={{ padding: 0 }}>
            {!!assetData.assetData ? (
              <AssetDetail
                {...props}
                assetData={assetData.assetData}
                items={assetData.items}
                books={assetData.books}
              />
            ) : (
              <LoadingCircle />
            )}
          </DialogContent>
        )}
      </Dialog>

      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        Asset Analysis (AM70.1)
      </Typography>

      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={3}
            >
              {[
                {
                  id: 'company',
                  label: 'Company',
                  optionLabel: val => `${val.id}`,
                  optionRender: null,
                  required: true
                },
                {
                  id: 'leaseCompany',
                  label: 'Lease Company',
                  optionLabel: val => `${val.description}`,
                  optionRender: null,
                  required: false
                },
                {
                  id: 'lease',
                  label: 'Lease',
                  optionLabel: val => `${val.description}`,
                  optionRender: null,
                  required: false
                },
                {
                  id: 'location',
                  label: 'Location',
                  optionLabel: val => `${val.description}`,
                  optionRender: null,
                  required: false
                },
                {
                  id: 'division',
                  label: 'Division',
                  optionLabel: val => `${val.description}`,
                  optionRender: null,
                  required: false
                },
                {
                  id: 'assetType',
                  label: 'Asset Type',
                  optionLabel: val => `${val.description}`,
                  optionRender: null,
                  required: false
                },
                {
                  id: 'subType',
                  label: 'Sub Type',
                  optionLabel: val => `${val.description}`,
                  optionRender: null,
                  required: false
                },
                {
                  id: 'assetGroup',
                  label: 'Group',
                  optionLabel: val => `${val.description}`,
                  optionRender: null,
                  required: false
                }
              ].map(field => {
                const renderOp = !!field.optionLabel
                  ? field.optionLabel
                  : option => {
                      return (
                        <div>
                          <Typography
                            style={{ fontSize: '13px' }}
                            variant='body1'
                            color='textPrimary'
                          >
                            {`${option.id}`}
                          </Typography>
                        </div>
                      )
                    }
                const optionLabel = field.optionLabel
                  ? field.optionLabel
                  : val => `${val.id}`
                return (
                  <Grid key={field.id} item>
                    <SimpleAutoComplete
                      id={field.id}
                      required={field.required}
                      name={field.id}
                      width='170px'
                      variant='standard'
                      label={field.label}
                      value={headers[field.id]}
                      onChange={val => handleChange(field.id, val)}
                      options={headerOptions[field.id]}
                      getOptionLabel={optionLabel}
                      renderOption={renderOp}
                    />
                  </Grid>
                )
              })}
            </Grid>
            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
            </div>
          </form>
        </Paper>

        {showTable && (
          <div style={{ marginTop: '1rem ' }}>
            <DashboardAthenaTableServerSide
              {...props}
              key={state.tableKey}
              params={{ ...headers }}
              tableColumns={am70ReactTableCols}
              reportName='am_am70_read_serverside'
              tableTitle={''}
              callback={handleCallback}
              transform={tableDataTransformation}
              noPagination
              downloadAction={'am_am70_read_serverside_download'}
              downloadFileName={'GL_Trans_Download'}
            />
          </div>
        )}
      </div>
    </div>
  )
}

// import React, { useState, useEffect } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
// import {
//   IconButton,
//   Grid,
//   CircularProgress,
//   Dialog,
//   Button,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   DialogContentText,
//   Tooltip,
//   Slide,
//   Typography
// } from '@material-ui/core'
// import ArrowBackIcon from '@material-ui/icons/ArrowBack'
// import LambdaFetch from '../functions/FetchFromLambda'
// import { printManagerCols } from '../constants/PrintManagerCols'
// import { Skeleton } from '@material-ui/lab'
// import TableMui from '../components/TableMui'
// import getTableOptions from '../constants/TableOptions'
// import { smallScreenWidth } from '../constants/AppConstants'
// import CachedIcon from '@mui/icons-material/Cached'

// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//     margin: '1rem'
//   }
// }))

// const Transition = React.forwardRef(function Transition (props, ref) {
//   return <Slide direction='up' ref={ref} {...props} />
// })

// export default function PrintManager (props) {
//   const [state, setstate] = useState({
//     printManagerData: [],
//     downloadId: null,
//     isFetching: false
//   })
//   const [deleteData, setDeleteData] = useState({
//     isOpen: false,
//     isLoading: false,
//     files: []
//   })
//   const [params, setParams] = useState(null)
//   const [paramsHeader, setParamsHeader] = useState(null)
//   const classes = useStyles()

//   const { credentials } = props.fetchInitialData

//   const fetch = async () => {
//     try {
//       const resp = await LambdaFetch(
//         'reports',
//         'post',
//         credentials.user.accessToken,
//         JSON.stringify({
//           action: 'print-manager-read'
//         }),
//         '',
//         credentials
//       )
//       const printData = resp.data.printManagerData.map(document => {
//         return {
//           ...document,
//           params_link: document.params ? (
//             <div
//               className='editLink'
//               onClick={() => {
//                 setParams(document.params)
//                 setParamsHeader(document.event_name)
//               }}
//             >
//               view
//             </div>
//           ) : (
//             ''
//           )
//         }
//       })
//       setstate({ ...state, printManagerData: printData, isFetching: false })
//     } catch (e) {
//       console.log(e)
//     }
//   }
//   useEffect(() => {
//     fetch()
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [])

//   const getFile = async (key, name, id) => {
//     setstate({ ...state, downloadId: id })
//     const myKey = `${key}.part_00000`
//     try {
//       const resp = await LambdaFetch(
//         'get-s3-object',
//         'post',
//         credentials.user.accessToken,
//         JSON.stringify({
//           key: myKey,
//           name: name,
//           time: 60 * 5
//         }),
//         '',
//         credentials
//       )

//       if (resp.success) {
//         const downloadUrl = resp.data.url
//         window.open(downloadUrl, '_blank')
//       }
//       setstate({ ...state, downloadId: null })
//     } catch (e) {
//       console.log(e)
//       setstate({ ...state, downloadId: null })
//     }
//   }

//   const confirmDelete = async info => {
//     const deleteIndeices = info.data.reduce((acc, cur) => {
//       return [...acc, cur.dataIndex]
//     }, [])

//     const deleteFiles = state.printManagerData
//       .slice()
//       .filter((row, index) => {
//         return deleteIndeices.includes(index)
//       })
//       .map(row =>
//         Object.assign(
//           {},
//           { id: row.id, document_link: row.document_link, name: row.event_name }
//         )
//       )

//     try {
//       await LambdaFetch(
//         'get-s3-object',
//         'post',
//         credentials.user.accessToken,
//         JSON.stringify({
//           action: 'delete',
//           files: deleteFiles
//         }),
//         'Successfully deleted file(s)',
//         credentials
//       )
//       fetch()
//     } catch (e) {
//       console.log(e)
//     }
//   }
//   // const deletFiles = async (currentFiles) => {
//   //   setDeleteData({...deleteData, isLoading: true})
//   //   try {
//   //     await LambdaFetch(
//   //       'get-s3-object',
//   //       'post',
//   //       credentials.user.accessToken,
//   //       JSON.stringify({
//   //         action: 'delete',
//   //         files: currentFiles
//   //       }),
//   //       'Successfully deleted file(s)',
//   //       credentials
//   //     )
//   //     setDeleteData({...deleteData, isLoading: false, isOpen: false, files: []})
//   //     fetch()
//   //   } catch (e) {
//   //   }
//   // }
//   const Refresh = () => (
//     <Tooltip disableFocusListener title='Refresh'>
//       <IconButton
//         onClick={() => {
//           const skelFill = Array(state.printManagerData.length).fill(
//             printManagerCols.reduce((acc, el) => {
//               acc[el.name] = <Skeleton height={20} />
//               return acc
//             })
//           )
//           setstate({ ...state, printManagerData: skelFill, isFetching: true })
//           fetch()
//         }}
//       >
//         <CachedIcon />
//       </IconButton>
//     </Tooltip>
//   )

//   const options = {
//     ...getTableOptions({
//       cols: printManagerCols,
//       fileName: `Print_Manager`
//     }),
//     selectableRows: 'multiple',
//     onRowsDelete: confirmDelete,
//     responsive: 'standard',
//     customToolbar: Refresh,
//     filter: false
//   }

//   const printTableData = state.printManagerData.map(row => {
//     row.link =
//       row.status === 'pending' ? (
//         <div style={{ margin: 'auto' }}>pending...</div>
//       ) : (
//         <div>
//           {state.downloadId === row.id ? (
//             <div style={{ paddingLeft: '1rem' }}>
//               <CircularProgress
//                 className='loading-circle'
//                 disableShrink
//                 size={16}
//               />
//             </div>
//           ) : state.isFetching ? (
//             ''
//           ) : (
//             <div
//               className='editLink'
//               onClick={() =>
//                 getFile(row.document_link, `${row.event_name}`, row.id)
//               }
//             >
//               download
//             </div>
//           )}
//         </div>
//       )
//     return row
//   })

//   return (
//     <>
//       {params && (
//         <Dialog
//           fullWidth
//           scroll={'paper'}
//           open={params}
//           aria-labelledby='alert-dialog-title'
//           aria-describedby='alert-dialog-description'
//         >
//           <DialogTitle>{`${paramsHeader} - params`}</DialogTitle>

//           <DialogContent dividers>
//          <RenderParams data={JSON.parse(params)}/>
//             {/* {Object.keys(JSON.parse(params)).map(function (key) {
//               return (
//                 <Typography variant='body1'>{`${key}: ${
//                   JSON.parse(params)[key]
//                 }`}</Typography>
//               )
//             })} */}
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={() => setParams(null)}>Close</Button>
//           </DialogActions>
//         </Dialog>
//       )}
//       <div
//         style={{
//           margin:
//             props.fetchInitialData.credentials.appWidth < smallScreenWidth
//               ? '1rem auto'
//               : '1rem'
//         }}
//       >
//         <Grid container>
//           <Grid item xs={12} style={{ position: 'relative' }}>
//             <TableMui
//               cols={printManagerCols}
//               data={printTableData}
//               options={options}
//               title=''
//             />
//           </Grid>
//         </Grid>
//       </div>
//     </>
//   )
// }

// const RenderParams = ({ data, parentKey = null, indentLevel = 0 }) => {
//   return (
//     <div style={{ marginLeft: `${indentLevel * 20}px` }}>
//       {Object.keys(data).map(key => (
//         <div key={key}>
//           {typeof data[key] !== 'object' ? (
//             <Typography variant='body1'>
//               {key}: {data[key]}
//             </Typography>
//           ) : (
//             <div>
//               {parentKey && <strong>{parentKey}:</strong>} {key}:
//               <RenderParams data={data[key]} parentKey={key} indentLevel={indentLevel + 1} />
//             </div>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Grid,
  CircularProgress,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Tooltip,
  Slide,
  Typography
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import { printManagerCols } from '../constants/PrintManagerCols'
import { Skeleton } from '@material-ui/lab'
import TableMui from '../components/TableMui'
import getTableOptions from '../constants/TableOptions'
import { smallScreenWidth } from '../constants/AppConstants'
import CachedIcon from '@mui/icons-material/Cached'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '1rem'
  }
}))

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function PrintManager (props) {
  const [state, setstate] = useState({
    printManagerData: [],
    downloadId: null,
    isFetching: false
  })
  const [deleteData, setDeleteData] = useState({
    isOpen: false,
    isLoading: false,
    files: []
  })
  const [params, setParams] = useState(null)
  const [paramsHeader, setParamsHeader] = useState(null)
  const classes = useStyles()

  const { credentials } = props.fetchInitialData

  const fetch = async () => {
    try {
      const resp = await LambdaFetch(
        'reports',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'print-manager-read'
        }),
        '',
        credentials
      )
      const printData = resp.data.printManagerData.map(document => {
        return {
          ...document,
          status: document.status.toLowerCase(),
          params_link: document.params ? (
            <div
              className='editLink'
              onClick={() => {
                setParams(document.params)
                setParamsHeader(document.event_name)
              }}
            >
              view
            </div>
          ) : (
            ''
          )
        }
      })
      setstate({ ...state, printManagerData: printData, isFetching: false })
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchAthenaFile = async (key, fileName) => {
    setstate({ ...state, downloadId: key })
    try {
      const resp = await LambdaFetch(
        'reports-athena',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-athena-file',
          doc_link: key,
          file_name: fileName
        }),
        '',
        credentials
      )
  
      if (resp.success) {
        const downloadUrl = resp.data.presignedUrl
        window.open(downloadUrl, '_blank')
      }
      setstate({ ...state, downloadId: null })
    } catch (e) {
      console.log(e)
      setstate({ ...state, downloadId: null })
    }
  }

  const getFile = async (key, name, id) => {
    setstate({ ...state, downloadId: id })
    const myKey = `${key}.part_00000`
    try {
      const resp = await LambdaFetch(
        'get-s3-object',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          key: myKey,
          name: name,
          time: 60 * 5
        }),
        '',
        credentials
      )

      if (resp.success) {
        const downloadUrl = resp.data.url
        window.open(downloadUrl, '_blank')
      }
      setstate({ ...state, downloadId: null })
    } catch (e) {
      console.log(e)
      setstate({ ...state, downloadId: null })
    }
  }

  const confirmDelete = async info => {
    const deleteIndeices = info.data.reduce((acc, cur) => {
      return [...acc, cur.dataIndex]
    }, [])

    const deleteFiles = state.printManagerData
      .slice()
      .filter((row, index) => {
        return deleteIndeices.includes(index)
      })
      .map(row =>
        Object.assign(
          {},
          { id: row.id, document_link: row.document_link, name: row.event_name }
        )
      )

    try {
      await LambdaFetch(
        'get-s3-object',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'delete',
          files: deleteFiles
        }),
        'Successfully deleted file(s)',
        credentials
      )
      fetch()
    } catch (e) {
      console.log(e)
    }
  }
  // const deletFiles = async (currentFiles) => {
  //   setDeleteData({...deleteData, isLoading: true})
  //   try {
  //     await LambdaFetch(
  //       'get-s3-object',
  //       'post',
  //       credentials.user.accessToken,
  //       JSON.stringify({
  //         action: 'delete',
  //         files: currentFiles
  //       }),
  //       'Successfully deleted file(s)',
  //       credentials
  //     )
  //     setDeleteData({...deleteData, isLoading: false, isOpen: false, files: []})
  //     fetch()
  //   } catch (e) {
  //   }
  // }
  const Refresh = () => (
    <Tooltip disableFocusListener title='Refresh'>
      <IconButton
        onClick={() => {
          const skelFill = Array(state.printManagerData.length).fill(
            printManagerCols.reduce((acc, el) => {
              acc[el.name] = <Skeleton height={20} />
              return acc
            })
          )
          setstate({ ...state, printManagerData: skelFill, isFetching: true })
          fetch()
        }}
      >
        <CachedIcon />
      </IconButton>
    </Tooltip>
  )

  const options = {
    ...getTableOptions({
      cols: printManagerCols,
      fileName: `Print_Manager`
    }),
    selectableRows: 'multiple',
    onRowsDelete: confirmDelete,
    responsive: 'standard',
    customToolbar: Refresh,
    filter: false
  }

  const printTableData = state.printManagerData.map(row => {
    row.link =
      row.status === 'pending' ? (
        <div style={{ margin: 'auto' }}>pending...</div>
      ) : (
        <div>
          {state.downloadId === row.id ? (
            <div style={{ paddingLeft: '1rem' }}>
              <CircularProgress
                className='loading-circle'
                disableShrink
                size={16}
              />
            </div>
          ) : state.isFetching ? (
            ''
          ) : (
            <>
              {row.status.toLowerCase() == 'success' && (
                <div
                  className='editLink'
                  onClick={() => {
                    row.report_source === 'athena'
                      ? fetchAthenaFile(row.document_link, row.event_name)
                      : getFile(row.document_link, `${row.event_name}`, row.id)
                  }}
                >
                  download
                </div>
              )}
            </>
          )}
        </div>
      )
    return row
  })

  return (
    <>
      {params && (
        <Dialog
          fullWidth
          scroll={'paper'}
          open={params}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle>{`${paramsHeader} - params`}</DialogTitle>

          <DialogContent dividers>
            <RenderParams data={JSON.parse(params)} />
            {/* {Object.keys(JSON.parse(params)).map(function (key) {
              return (
                <Typography variant='body1'>{`${key}: ${
                  JSON.parse(params)[key]
                }`}</Typography>
              )
            })} */}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setParams(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      <div
        style={{
          margin:
            props.fetchInitialData.credentials.appWidth < smallScreenWidth
              ? '1rem auto'
              : '1rem'
        }}
      >
        <Grid container>
          <Grid item xs={12} style={{ position: 'relative' }}>
            <TableMui
              cols={printManagerCols}
              data={printTableData}
              options={options}
              title=''
            />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

const RenderParams = ({ data, parentKey = null, indentLevel = 0 }) => {
  return (
    <div style={{ marginLeft: `${indentLevel * 20}px` }}>
      {data && Object.keys(data).map(key => (
        <div key={key}>
          {typeof data[key] !== 'object' ? (
            <Typography variant='body1'>
              {key}: {data[key]}
            </Typography>
          ) : (
            <div>
              {parentKey && <strong>{parentKey}:</strong>} {key}:
              <RenderParams
                data={data[key]}
                parentKey={key}
                indentLevel={indentLevel + 1}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

import React, { useEffect, useMemo, useState } from 'react'
import MaterialReactTable from 'material-react-table'
import LambdaFetch from '../functions/FetchFromLambda'
import { debounce } from 'throttle-debounce'
import { Box, IconButton } from '@mui/material'
import { ExportToCsv } from 'export-to-csv' //or use your library of choice here
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { v4 as uuidv4 } from 'uuid'
import CustomFooter from '../utils/CustomDataTableFooter'
import moment, { invalid } from 'moment'
import { SettingsSharp } from '@material-ui/icons'
import AthenaParameterTransformation from '../utils/AthenaParameterTransformation'

export const DashboardAthenaTableServerSideNoSelect = props => {
  //data and fetching state
  const [data, setData] = useState([])
  const [initalRender, setInitalRender] = useState(true)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isRefetching, setIsRefetching] = useState(false)
  const [rowCount, setRowCount] = useState(0)
  const [executionId, setExecutionId] = useState(null)
  //table state
  const [columnFilters, setColumnFilters] = useState([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100
  })

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true
  }

  const csvExporter = new ExportToCsv(csvOptions)
  useEffect(() => {
    if (initalRender) return
    setPagination({ ...pagination, pageIndex: 0 })
    debounceFetchTableData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters])


  useEffect(() => {
    if (initalRender) return
    debounceFetchTableData()
  }, [sorting]);


  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getFilters = (isDownload = false) => {

    let invalidDate = false
    const filters = columnFilters.reduce((acc, cur, index) => {
   
      if(isDownload) {
      for (const sf in props.separatedDateFilters) {
        if (cur['id'] === sf) {
          const beginDateName = props.separatedDateFilters[sf][0]
          const endDateName = props.separatedDateFilters[sf][1]
          acc[beginDateName] = cur.value[0]
            ? cur.value[0].format(moment.HTML5_FMT.DATE)
            : ''
          acc[endDateName] = cur.value[1]
            ? cur.value[1].format(moment.HTML5_FMT.DATE)
            : ''
        }
      }
      // if(!Array.isArray(cur.value)) {
      // acc[cur['id']] = cur.value ? cur.value : ''
      // }
      acc[cur['id']] = cur.value ? cur.value : ''

    } else {
      if(Array.isArray(cur.value)) {
        const [beginDate,endDate] = [cur.value[0] ? cur.value[0].format(moment.HTML5_FMT.DATE) : '', cur.value[1] ? cur.value[1].format(moment.HTML5_FMT.DATE) : '']
        if(beginDate === 'Invalid Date' || endDate === 'Invalid Date') invalidDate = true
        if(!beginDate && beginDate < '1900-01-01') invalidDate = true
          if(!endDate && endDate < '1900-01-01') invalidDate = true
      } 
      acc[cur['id']] = cur.value ? cur.value : ''
    
    }
      return acc
    }, {})

    const hasInvalidDate = Object.values(filters).includes('Invalid Date')
    if (hasInvalidDate || invalidDate) {
      return false
    } else {
      return filters
    }
  }

  const getSortObj = () => {
    if(!sorting) return {}
    const sortObj = sorting[0]
    if(!sortObj) return {}
    return {
      column: sortObj?.id,
      order: sortObj['desc'] ? 'desc' : 'asc'
    }
  }
  const fetchData = async () => {
    const filters = getFilters()
    if (!filters) return null
    if (!data.length) {
      setIsLoading(true)
    } else {
      setIsRefetching(true)
    }
    try {
      const params = AthenaParameterTransformation(
        props.reportName,
        props.params,
        filters
      )

      const fetchBody = {
        dashboard_name: props.reportName,
        ...params,
        roles: 'app_user,all-access,default'
      }

      const resp = await LambdaFetch(
        'dashboard-trigger',
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify(fetchBody),
        '',
        props.fetchInitialData.credentials
      )

      if (resp.data.response.errorMessage) {
        window.alert(resp.data.response.errorMessage)
        setIsError(true)
        props.callback(null, resp.data.response.errorMessage)
        return
      }
      const data = resp.data.response.body.data === '[]' ? [] : resp.data.response.body.data
      const executionId = resp.data.response.body.execution_id
      const rowCount = resp.data.response.body.total_record_count

      let tableData = props.transform ? props.transform(data) : data
      setExecutionId(executionId)
      setRowCount(rowCount)
      setData(tableData)
      setInitalRender(false)
      if (props.callback) {
        props.callback()
      }
    } catch (error) {
      setIsError(true)
      console.error(error)
      props.callback(null, error)
      return
    }
    setIsError(false)
    setIsLoading(false)
    setIsRefetching(false)
  }

  const updateInnerSearch =(obj,cols) => {
    Object.keys(obj).forEach(cur => {
      const columnMetaData = cols.find(col => col.accessorKey === cur)
      if(!!columnMetaData.innerSearch) {
        obj[cur] = `%${obj[cur]}`
      }
    })
    return obj
  }

  const updateTrimSearch =(obj,cols) => {
    Object.keys(obj).forEach(cur => {
      const columnMetaData = cols.find(col => col.accessorKey === cur)
      if(!!columnMetaData.trimSearch) {
        const property = cur
        const value = obj[cur]
        delete obj[cur];
        obj[`trim(${property})`] = value
      }
    })
    return obj
  }

  const fetchUpdatedData = async (page = 0, pageSize=100) => {
    const filters = getFilters()
    const filtersPlusWildCards = updateInnerSearch(filters,props.tableColumns)
    const filtersPlusTrims = updateTrimSearch(filtersPlusWildCards, props.tableColumns)

    if (!filters) return null
    if (!data.length) {
      setIsLoading(true)
    } else {
      setIsRefetching(true)
    }

    const body = {
      executionId: executionId,
      filters: filtersPlusTrims,
      page: page + 1,
      sortObj: getSortObj(),
      pageSize: pageSize,
      ...props.params,
      roles: 'app_user,all-access,default'
    }

    try {
      const resp = await LambdaFetch(
        'dashboard-serverside-filter-test',
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify(body),
        '',
        props.fetchInitialData.credentials
      )
      const data = resp.data.data === '[]' ? [] : resp.data.data
      const rowCount = resp.data.total_record_count


      let tableData = props.transform ? props.transform(data) : data

      setData(tableData)
      setRowCount(rowCount)
      setInitalRender(false)
      if (props.callback) {
        props.callback()
      }
    } catch (error) {
      setIsError(true)
      console.error(error)
      props.callback(null, error)
      return
    }
    setIsError(false)
    setIsLoading(false)
    setIsRefetching(false)
  }

  const debounceFetchTableData = debounce(1000, false, () => {
    fetchUpdatedData()
  })

  const handleExportData = () => {
    DownloadData()
  }

  const DownloadData = async () => {
    const filters = getFilters()
    const filtersPlusWildCards = updateInnerSearch(filters,props.tableColumns)
    const filtersPlusTrims = updateTrimSearch(filtersPlusWildCards, props.tableColumns)

    if (!filters) return null
    try {
      const params = AthenaParameterTransformation(
        `${props.reportName}_download`,
        props.params,
        filters
      )

      const fetchBody = {
        download: true,
        ...params,
        reportingParams: JSON.stringify({ ...params }),
        executionId,
        filters: filtersPlusTrims,
        sortObj: getSortObj(),
        event_name: `${props.reportName}_download`,
        report_name: props.reportName,
        roles: 'app_user,all-access,default'
      }
      const resp = await LambdaFetch(
        'dashboard-serverside-download',
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify(fetchBody),
        '',
        props.fetchInitialData.credentials
      )

      if (resp.data.response.errorMessage) {
        window.alert(resp.data.response.errorMessage)
        setIsError(true)
        props.callback(null, resp.data.response.errorMessage)
        return
      }
      if(resp.data.response.$metadata.httpStatusCode === 200)  {
        props.fetchInitialData.createSnack(
          'File sent to print manager',
          'success',
          3000
        );
      }
    } catch (error) {
      setIsError(true)
      console.error(error)
      props.callback(null, error)
      return
    }
  }
  return (
    <MaterialReactTable
      columns={props.tableColumns.map(r => {
        return { ...r, size: 1 }
      })}
      data={data}
      initialState={{
        ...props.initialState,
        showColumnFilters: true,
        density: 'compact'
      }}
      manualFiltering
      manualPagination
      manualSorting
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data'
            }
          : undefined
      }
      enableGlobalFilter={false}
      enableStickyHeader
      enableFullScreenToggle={false}
      showSkeletons={true}
      muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
      onColumnFiltersChange={a => {
        setColumnFilters(a)
      }}
      onGlobalFilterChange={setGlobalFilter}
      maxMultiSortColCount={1}
      onSortingChange={setSorting}
      muiTableBodyRowProps={({ row, table }) => {
        if (row.index % 2 === 0) {
          return { style: { background: '#F7F7F7' } }
        }
      }}
      count={rowCount}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting
      }}
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
        >
          <IconButton onClick={handleExportData} icon={<CloudDownloadIcon />}>
            <CloudDownloadIcon />
          </IconButton>
        </Box>
      )}
      renderBottomToolbar={({ table }) => {
        return (
          <CustomFooter
            {...props}
            count={rowCount}
            page={pagination.pageIndex}
            rowsPerPage={pagination.pageSize}
            changeRowsPerPage={num => {
              setPagination({ ...pagination, pageSize: num })
              fetchUpdatedData(pagination.page, num)
            }}
            changePage={num => {
              setPagination({ ...pagination, pageIndex: num })
              fetchUpdatedData(num, pagination.pageSize)
            }}
            tableData={data}
            noPagination={false}
            textLabels={{ displayRows: 'of' }}
          />
        )
      }}
    />
  )
}

export default DashboardAthenaTableServerSideNoSelect

import { currencyFormat, dateFormat } from '../utils/formatter';
import useDateRangeCol from './useDateRangeCol';

const useCashBookTransServerSideCols = () => {
  const issueDateCol = useDateRangeCol('ISSUE_DATE', 'Issue Date');
  // const reconDateCol = useDateRangeCol('RECON_DATE', 'Recon Date');

  const cashBookTransServerSideCols = [
    {
      accessorKey: 'CASH_CODE',
      header: 'Cash Code',
      enableSorting: false,
      enableColumnFilter: true,
      filterFn: 'contains',
    },
    {
      accessorKey: 'BANK_INST_CODE',
      header: 'Payment',
      enableSorting: false,
      enableColumnFilter: true,
      filterFn: 'contains',
    },
    {
      accessorKey: 'SOURCE_CODE',
      header: 'Source Code',
      enableSorting: false,
      enableColumnFilter: true,
    },
    {
      accessorKey: 'COMPANY',
      header: 'Company',
      enableSorting: false,
      enableColumnFilter: true,
      filterFn: 'contains',
    },
    {
      accessorKey: 'TRANS_NBR',
      header: 'Transaction',
      enableSorting: false,
      enableColumnFilter: false,
      filterFn: 'contains',
    },
    issueDateCol,
    {
      accessorKey: 'DESCRIPTION',
      header: 'Description',
      enableSorting: false,
      enableColumnFilter: true,
      filterFn: 'contains',
    },
    {
      accessorKey: 'ISSUE_BNK_AMT',
      header: 'Amount',
      enableSorting: false,
      enableColumnFilter: false,
      filterFn: 'contains',
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    {
      accessorKey: 'BNK_TRANS_SIGN',
      header: 'Sign',
      enableSorting: false,
      enableColumnFilter: false,
      filterFn: 'contains',
    },
    {
      accessorKey: 'REC_STATUS',
      header: 'Status',
      enableSorting: false,
      enableColumnFilter: true,
      filterVariant: 'select',
      filterSelectOptions: [
        'O',
        'R',
        'V',
        'S',
        'D',
        'E'
      ]
    },
    {
      accessorKey: 'RECON_DATE',
      header: 'Recon Date',
      enableSorting: false,
      enableColumnFilter: false,
      filterFn: 'contains',
      Cell: ({ cell }) => dateFormat(cell.getValue()),
    },
    {
      accessorKey: 'VIEW_DISTRIB',
      header: 'Distribution',
      enableSorting: false,
      enableColumnFilter: false,
      },
  ];

  return cashBookTransServerSideCols;
};

export { useCashBookTransServerSideCols };

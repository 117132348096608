import React, { useState } from 'react'
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Paper,
  Slide
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SimpleTable from '../components/common/SimpleTable'
import { dateFormat } from '../utils/formatter'
import { useTaxCols } from '../hooks/EmployeeCols'
import ReactTable from '../components/ReactTable';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%'
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' }
}))

export default function EmployeeTax (props) {
  const classes = useStyles()
  const { credentials } = props.fetchInitialData

  const printValue = (dataObj, lookup, description, secondary, secondType) => {
    const isDate = description.toLowerCase().indexOf('date') !== -1

    return (
      <div>
        <span className={classes.descript}>{`${description}:  `}</span>
        <span className={classes.descriptVal}>
          {`${isDate ? dateFormat(dataObj[lookup]) : dataObj[lookup]}${
            secondary && dataObj[secondary]
              ? ` (${
                  secondType !== 'date'
                    ? dataObj[secondary]
                    : dateFormat(dataObj[secondary])
                })`
              : ''
          } `}
        </span>
      </div>
    )
  }

  const { taxHeader, taxDetail } = props.data
  const taxCols = useTaxCols()
console.log(props)
  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          spacing={3}
          direction='row'
          justify='flex-start'
          alignItems='stretch'
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper elevation={3} className={classes.paper}>
            <Grid
              container
              spacing={2}
              direction='row'
              justify='space-evenly'
              alignItems='flex-start'
            >
              <Grid item xs={12} sm={6} lg={6}>
                <div>Resident</div>
                {printValue(taxHeader,'TAX_STATE', 'State')}
                {printValue(taxHeader,'TAX_COUNTY', 'County')}
                {printValue(taxHeader,'TAX_CITY', 'City')}
                {printValue(taxHeader,'TAX_SCHOOL', 'Sch Dst')}
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
              <div>Work</div>
                {printValue(taxHeader,'WORK_STATE', 'State')}
                {printValue(taxHeader,'WORK_COUNTY', 'County')}
                {printValue(taxHeader,'WORK_CITY', 'City')}
                {printValue(taxHeader,'WORK_SCHOOL', 'Sch Dst')}
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                {printValue(taxHeader,'WC_STATE', 'Workers Comp State')}
                {printValue(taxHeader,'EIC_STATUS', 'EIC Status')}
                {printValue(taxHeader,'BSI_GROUP', 'Group Code')}
                {printValue(taxHeader,'TAX_FREQUENCY', 'Tax Frequency')}
              </Grid>
            </Grid>



            </Paper>
          </Grid>
        </Grid>
      </div>
      <div style={{marginTop: "1rem"}}>
      <ReactTable cols={taxCols} data={taxDetail} />
      </div>
    </>
  )
}

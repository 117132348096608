import React, { useEffect, useState } from 'react'
import { IconButton, Typography, Grid, Paper } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import { makeStyles } from '@material-ui/core/styles'
import LoadingCircle from '../components/common/LoadingCircle'
import { smallScreenWidth } from '../constants/AppConstants'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import LoadingButton from '../components/LoadingButton'
import AssetDetail from '../components/AssetDetail'
import AutocompleteDropDown from '../components/AutocompleteDropdown'
import fetchFromAthena from '../functions/FetchFromAthena'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%'
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' }
}))

export default function Assets (props) {
  const classes = useStyles()
  const { credentials } = props.fetchInitialData
  const [state, setstate] = useState({
    groups: [],
    selectedAsset: '',
    selectedCompany: '',
    assetData: null,
    items: [],
    books: [],
    fetched: false,
    isLoading: false
  })
  const [assestSelected, setAssestSelected] = React.useState('')
  const [companySelected, setCompanySelected] = React.useState(false)

  useEffect(() => {
    async function fetch () {
      try {
        const resp = await LambdaFetch(
          'vendors',
          'post',
          credentials.user.accessToken,
          JSON.stringify({
            action: 'get-vendorgroups'
          }),
          '',
          credentials
        )
        setstate({ ...state, groups: resp.data.groups, fetched: true })
      } catch (e) {
        console.log(e)
      }
    }

    fetch()
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    if (!assestSelected) return null
    getAsset(assestSelected)
  }
  const getAsset = async id => {
    try {
      if (!id || !companySelected) return null
      setstate({ ...state, isLoading: true })

      const queries = [
        'am_asset_read',
        'am_item_read',
        'am_book_read'
      ]
  
      const data = await Promise.allSettled(
        queries.map(query =>
          fetchFromAthena({
            queryName: query,
            headers: {
              company: companySelected,
              asset: id
            },
            accessToken: credentials.user.accessToken,
            credentials: credentials
          })
        )
      ).then(results => {
        return results
      })

      const [main, items,books] = [data[0].value[0], data[1].value, data[2].value]


const assetDesc = main.ASSET_DESC
      setstate({
        ...state,
        assetData: main,
        items: items.map(row => {
          return {
            ...row,
            COMPANY: companySelected,
            ASSET: id,
            ASSET_DESC: assetDesc
          }
        }),
        books: books.map(row => {
          return {
            ...row,
            COMPANY: companySelected,
            ASSET: id,
            ASSET_DESC: assetDesc
          }
        }),
        isLoading: false
      })






      // const resp = await LambdaFetch(
      //   'assets',
      //   'post',
      //   credentials.user.accessToken,
      //   JSON.stringify({
      //     action: 'asset-get',
      //     company: companySelected,
      //     asset: id
      //   }),
      //   '',
      //   credentials
      // )
      // const assetDesc = resp.data.asset[0].ASSET_DESC
      // setstate({
      //   ...state,
      //   assetData: resp.data.asset[0],
      //   items: resp.data.items.map(row => {
      //     return {
      //       ...row,
      //       COMPANY: companySelected,
      //       ASSET: id,
      //       ASSET_DESC: assetDesc
      //     }
      //   }),
      //   books: resp.data.books.map(row => {
      //     return {
      //       ...row,
      //       COMPANY: companySelected,
      //       ASSET: id,
      //       ASSET_DESC: assetDesc
      //     }
      //   }),
      //   isLoading: false
      // })
    } catch (e) {
      console.log(e)
    }
  }

  const { assetData, items, books } = state

  if (!state.fetched) {
    return <LoadingCircle />
  }
  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        Asset (AM20)
      </Typography>

      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={2}
            >
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'company'}
                  name={'Company'}
                  width='125px'
                  key={'company'}
                  required={true}
                  variant='standard'
                  label={'Company'}
                  value={state.selectedCompany}
                  category={'company'}
                  screen={'asset'}
                  onChange={val => setCompanySelected(val ? val.key_value : '')}
                />
              </Grid>
              <Grid item>
                <ServerSideAutoCompletev2
                  id='asset'
                  required={true}
                  width='320px'
                  label='Asset'
                  accessToken={credentials.user.accessToken}
                  error={
                    !companySelected
                      ? 'Company must be selected'
                      : 'No Company found'
                  }
                  variant='standard'
                  handleChange={(id, hit) => {
                    setAssestSelected(id)
                  }}
                  credentials={credentials}
                  apiResource='assets'
                  secondaryParams={{
                    limit: 100,
                    company: companySelected,
                    action: 'asset-autocomplete'
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
            </div>
          </form>
        </Paper>
        {!!state.assetData && (
          <AssetDetail
            {...props}
            assetData={assetData}
            items={items}
            books={books}
          />
        )}
      </div>
    </div>
  )
}

import React, { useEffect, useMemo, useState } from 'react'
import MaterialReactTable from 'material-react-table'
import LambdaFetch from '../functions/FetchFromLambda'
import { Box, IconButton } from '@mui/material'
import { ExportToCsv } from 'export-to-csv' //or use your library of choice here
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import AthenaParameterTransformation from '../utils/AthenaParameterTransformation'

const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true
  }
  
  const csvExporter = new ExportToCsv(csvOptions)


export const DashboardAthenaTableClientSide = props => {
    const [data,setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {

    const params = AthenaParameterTransformation(props.queryName, props.headers)
    const fetchBody = {
      dashboard_name: props.queryName,
      ...params,
      pageSize: 9999,
      roles: 'app_user,all-access,default'
    }
    const resp = await LambdaFetch(
      'dashboard-trigger',
      'post',
      props.fetchInitialData.credentials.user.accessToken,
      JSON.stringify(fetchBody),
      '',
      props.credentials
    )
      setIsLoading(false)
    setData(resp.data.response.body.data)
  }

  const handleExportRows = rows => {
    const displayRows = rows.map((row) => {
      Object.keys(row.original).forEach(header => {
        if(typeof row.original[header] === 'object'){
          row.original[header] = ''
        }
      })
     return  row.original
    })

     csvExporter.generateCsv(displayRows);
  }
  return (
    <MaterialReactTable
      columns={props.cols.map(r => {
        return {...r, size:1}
      })}
      data={data}
      initialState={{ showColumnFilters: true, density: 'compact' }}
      muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
      enableStickyHeader
      enableFullScreenToggle={false}
      showSkeletons={true}
      enableFilterRankedResults={false}
      enableGlobalFilter={false} //turn off a feature
      state={{
        isLoading: isLoading,
      }}
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
        >
          <IconButton
            onClick={() => {
              handleExportRows(table.getPrePaginationRowModel().rows)
            }}
            icon={<CloudDownloadIcon />}
          >
            <CloudDownloadIcon />
          </IconButton>
        </Box>
      )}
    />
  )
}

export default DashboardAthenaTableClientSide
